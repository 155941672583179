import React, { Component } from "react";
import { API } from "aws-amplify";
import {FormGroup, FormControl, ControlLabel, Button} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./NewSchedina.css";
import DailyDareEditor from "../components/DailyDareEditor";
import DateTimePicker from "react-datetime-picker";
import MatchEditor from "../components/MatchEditor";

export default class DailyDare extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      isDeleting: null,
      name: "",
      startDate: new Date(),
      endDate: new Date(),
      startLive: new Date(),
      endLive: new Date(),
      matches: [],
      dailydare_id: null
    };
  }

  async componentDidMount() {
    try {
      let attachmentURL;
      const dailydare = await this.getDailyDare();
      if (dailydare == null) {
        return;
      }
      const { name, matches, dailydare_id } = dailydare;
      this.state.startDate = new Date(dailydare.start_date);
      this.state.endDate = new Date(dailydare.end_date);
      this.state.startLive = new Date(dailydare.start_live);
      this.state.endLive = new Date(dailydare.end_live);

      this.setState({
        name,
        matches,
        dailydare_id
      });
    } catch (e) {
      alert(e);
    }
  }

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm("Sicuro di cancellare?");

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteDailyDare();
      this.props.history.push("/dailydare");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  };
  openStats = () => {
    this.props.history.push('/dailydare/stats/' + this.props.match.params.id);
  }
  getDailyDare() {
    return API.get("news", `/dailydare_master/${this.props.match.params.id}`);
  }

  creatSchedina(note) {
    console.log(note);
    return API.post("news", "/dailydare_master", {
      body: note
    });
  }
  deleteDailyDare() {
    return API.del("news", `/dailydare_master/${this.props.match.params.id}`);
  }

  validateForm() {
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };
  onChangeStartDate = startDate => this.setState({ startDate });
  onChangeEndDate = endDate => this.setState({ endDate });
  onChangeStartLive = startLive => this.setState({ startLive });
  onChangeEndLive = endLive => this.setState({ endLive });

  onChange(field, value) {
    this.state.matches[field] = value;
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.creatSchedina(this.state);
      this.props.history.push("/dailydare");
    } catch (e) {
      alert(e + JSON.stringify(e));
      this.setState({ isLoading: false });
    }
  };

  handleAddQuestion = () => {
    this.setState({
      matches: this.state.matches.concat([{}])
    });
  };

  handleRemoveQuestion = idx => () => {
    this.setState({
      matches: this.state.matches.filter((s, sidx) => idx !== sidx)
    });
  };

  render() {
    return (
      <div className="NewSchedina">
        <Button bsStyle="warning" onClick={() => this.openStats()} >STATS</Button>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="name">
            <FormControl
              onChange={this.handleChange}
              value={this.state.name}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup bsSize="small" controlId="startDate">
            <ControlLabel>startDate</ControlLabel>
            <DateTimePicker
              value={this.state.startDate}
              onChange={this.onChangeStartDate}
              placeholder="startDate"
            />
          </FormGroup>
          <FormGroup bsSize="small" controlId="endDate">
            <ControlLabel>endDate</ControlLabel>
            <DateTimePicker
              value={this.state.endDate}
              onChange={this.onChangeEndDate}
              placeholder="endDate"
            />
          </FormGroup>
          <hr /> <hr />
          <FormGroup bsSize="small" controlId="startLive">
            <ControlLabel>startLive</ControlLabel>
            <DateTimePicker
              value={this.state.startLive}
              onChange={this.onChangeStartLive}
              placeholder="startLive"
            />
          </FormGroup>
          <FormGroup bsSize="small" controlId="endLive">
            <ControlLabel>endLive</ControlLabel>
            <DateTimePicker
              value={this.state.endLive}
              onChange={this.onChangeEndLive}
              placeholder="endLive"
            />
          </FormGroup>
          <hr />
          <button type="button" onClick={this.handleAddQuestion}>
            Aggiungi domanda
          </button>
          {this.state.matches.map((match, idx) => (
            <div>
              <hr />
              <button
                type="button"
                onClick={this.handleRemoveQuestion(idx)}
                className="small"
              >
                Rimuovi Domanda
              </button>

              <DailyDareEditor
                key={idx}
                value={this.state.matches[idx]}
                currentMatch={JSON.stringify(this.state.matches[idx])}
                keyword={idx}
                onChange={this.onChange.bind(this)}
              />

              <hr />
            </div>
          ))}
          <hr />
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
          <LoaderButton
            block
            bsStyle="danger"
            bsSize="large"
            isLoading={this.state.isDeleting}
            onClick={this.handleDelete}
            text="Delete"
            loadingText="Deleting…"
          />
        </form>
      </div>
    );
  }
}

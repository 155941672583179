import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import DateTimePicker from "react-datetime-picker";
import { CompactPicker } from "react-color";

class DailyDareEditor extends Component {
  componentWillReceiveProps(props) {
    if (props.value) {
      this.state.matchDate = props.value.matchDate;
      this.state.teamHome = props.value.teamHome;
      this.state.teamAway = props.value.teamAway;
      this.state.matchID = props.value.matchID;
      this.state.quotaX = props.value.quotaX;
      this.state.quota1 = props.value.quota1;
      this.state.quota2 = props.value.quota2;
      this.state.teamHPColor = props.value.teamHPColor;
      this.state.teamHSColor = props.value.teamHSColor;
      this.state.teamAPColor = props.value.teamAPColor;
      this.state.teamASColor = props.value.teamASColor;
      this.state.team_away = props.value.team_away;
      this.state.team_home = props.value.team_home;
      this.state.results = props.value.results;
      this.state.question = props.value.question;
      this.state.answer = props.value.answer;
      // alert(question);
    }
    // alert(props.value.question);
    // alert(JSON.stringify(props));
  }

  constructor(props) {
    super(props);

    this.matchID = parseInt(props["keyword"]);
    this.state = {
      matchDate: new Date(),
      teamHome: "",
      teamAway: "",
      matchID: "",
      quotaX: "0.0",
      quota1: "0.0",
      quota2: "0.0",
      teamHPColor: "",
      teamHSColor: "",
      teamAPColor: "",
      teamASColor: "",
      team_away: {
        name: "",
        primary_color: "",
        secondary_color: ""
      },
      team_home: {
        name: "",
        primary_color: "",
        secondary_color: ""
      },
      question: "",
      answer: "",
      results: {
        1: "",
        2: "",
        X: ""
      }
    };

    this.teams = {
      Milan: {
        p: "#ff0000",
        s: "#000000"
      },
      Juventus: {
        p: "#ffffff",
        s: "#000000"
      },
      Inter: {
        p: "#000000",
        s: "#0080FF"
      },
      Sassuolo: {
        p: "#00FF00",
        s: "#000000"
      },
      Bologna: {
        p: "#ff0000",
        s: "#0000ff"
      },
      Atalanta: {
        p: "#000000",
        s: "#0080FF"
      },
      Brescia: {
        p: "#0080FF",
        s: "#ffffff"
      },
      Cagliari: {
        p: "#ff0000",
        s: "#0000FF"
      },
      Fiorentina: {
        p: "#5C2E91",
        s: "#5C2E91"
      },
      Genoa: {
        p: "#ff0000",
        s: "#0000ff"
      },
      Lazio: {
        p: "#B2FFFF",
        s: "#ffffff"
      },
      Lecce: {
        p: "#FFFF00",
        s: "#FF0000"
      },
      Napoli: {
        p: "#0080FF",
        s: "#0080FF"
      },
      Parma: {
        p: "#FFFF00",
        s: "#0000FF"
      },
      Roma: {
        p: "#FFFF00",
        s: "#FF0000"
      },
      Sampdoria: {
        p: "#FFFFFF",
        s: "#0000FF"
      },
      SPAL: {
        p: "#B2FFFF",
        s: "#ffffff"
      },
      Torino: {
        p: "#7A001F",
        s: "#7A001F"
      },
      Udinese: {
        p: "#ffffff",
        s: "#000000"
      },
      Verona: {
        p: "#FFFF00",
        s: "#0000FF"
      }
    };

    this.componentWillReceiveProps(props);
  }

  componentWillUnmount() {
    // Make sure to remove the DOM listener when the component is unmounted.
    this.onSubmit(null);
  }

  onSubmit(e) {
    // alert("SUBMIT");
    // alert(this.state.quota1);
    this.state.team_away = {
      name: this.state.teamAway,
      primary_color: this.state.teamAPColor,
      secondary_color: this.state.teamASColor
    };
    this.state.team_home = {
      name: this.state.teamHome,
      primary_color: this.state.teamHPColor,
      secondary_color: this.state.teamHSColor
    };

    this.state.results = {
      1: this.state.quota1,
      2: this.state.quota2,
      X: this.state.quotaX
    };

    this.props.onChange(this.matchID, this.state);
  }

  handleAnswerChange = event =>  {
    // this.state.answer = event.target.value;
    this.setState({answer: event.target.value});
  }

  handleFieldChange = event => {
    this.setState(
      {
        [event.target.id]: event.target.value
      },
      () => {}
    );

    if (event.target.id == "teamHome") {
      if (this.teams[event.target.value]) {
        this.state.teamHPColor = this.teams[event.target.value].p;
        this.state.teamHSColor = this.teams[event.target.value].s;
      }
    }

    if (event.target.id == "teamAway") {
      if (this.teams[event.target.value]) {
        this.state.teamAPColor = this.teams[event.target.value].p;
        this.state.teamASColor = this.teams[event.target.value].s;
      }
    }

    setTimeout(() => {
      this.onSubmit(null);
    }, 1000);
  };
  onChangeDate = matchDate => {
    this.setState({ matchDate });
    this.props.onChange(this.matchID, this.state);
  };

  handleChangeCompleteHP = color => {
    this.setState({ teamHPColor: color.hex });
    this.props.onChange(this.matchID, this.state);
  };
  handleChangeCompleteHS = color => {
    this.setState({ teamHSColor: color.hex });
    this.props.onChange(this.matchID, this.state);
  };
  handleChangeCompleteAP = color => {
    this.setState({ teamAPColor: color.hex });
    this.props.onChange(this.matchID, this.state);
  };
  handleChangeCompleteAS = color => {
    this.setState({ teamASColor: color.hex });
    this.props.onChange(this.matchID, this.state);
  };

  render() {
    const loading = this.state.isProcessing || this.props.loading;

    return (
      <div>
        <FormGroup bsSize="small" controlId="matchDate">
          <ControlLabel>Orario Partita</ControlLabel>
          <DateTimePicker
            value={this.state.matchDate}
            onChange={this.onChangeDate}
            placeholder="matchDate"
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="question">
          <ControlLabel>Domanda</ControlLabel>
          <FormControl
            type="text"
            value={this.state.question}
            onChange={this.handleFieldChange}
            placeholder="Domanda..."
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="matchID">
          <ControlLabel>Match ID</ControlLabel>
          <FormControl
            type="text"
            value={this.state.matchID}
            onChange={this.handleFieldChange}
            placeholder="matchID"
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="teamHome">
          <ControlLabel>Team Home</ControlLabel>
          <FormControl
            type="text"
            value={this.state.teamHome}
            onChange={this.handleFieldChange}
            placeholder="Home"
          />
          <CompactPicker
            color={this.state.teamHPColor}
            onChangeComplete={this.handleChangeCompleteHP}
          />
          <CompactPicker
            color={this.state.teamHSColor}
            onChangeComplete={this.handleChangeCompleteHS}
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="teamAway">
          <ControlLabel>Team Away</ControlLabel>
          <FormControl
            type="text"
            value={this.state.teamAway}
            onChange={this.handleFieldChange}
            placeholder="Away"
          />
          <CompactPicker
            color={this.state.teamAPColor}
            onChangeComplete={this.handleChangeCompleteAP}
          />
          <CompactPicker
            color={this.state.teamASColor}
            onChangeComplete={this.handleChangeCompleteAS}
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="quota1">
          <ControlLabel>quota1</ControlLabel>
          <FormControl
            type="text"
            value={this.state.quota1}
            onChange={this.handleFieldChange}
            placeholder="quota1"
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="quotaX">
          <ControlLabel>quotaX</ControlLabel>
          <FormControl
            type="text"
            value={this.state.quotaX}
            onChange={this.handleFieldChange}
            placeholder="quotaX"
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="quota2">
          <ControlLabel>quota2</ControlLabel>
          <FormControl
            type="text"
            value={this.state.quota2}
            onChange={this.handleFieldChange}
            placeholder="quota2"
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="answer">
          <ControlLabel>Risultato:</ControlLabel>
          <FormControl
            type="text"
            value={this.state.answer}
            onChange={this.handleFieldChange}
            placeholder="answer"
          />
          
        </FormGroup>
        <hr />
      </div>
    );
  }
}

export default DailyDareEditor;

import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { PageHeader, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import "./Notes.css";

export default class TimeWindowList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            notes: []
        };

    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const notes = await this.notes();
            this.setState({ notes });
        } catch (e) {
            alert("aaa" + e);
        }

        this.setState({ isLoading: false });
    }

    notes() {
        return API.get("news", "/timewindow_master");
    }

    renderNotesList(notes) {
        return [{}].concat(notes).map((note, i) =>
            i !== 0 ? (
                <LinkContainer key={note.id_finestra} to={`/timewindow/${note.id_finestra}`}>
                    <ListGroupItem
                        header={note.name + " - (S: " + note.start_date + " - E: " + note.end_date + ")"}
                    >

                    </ListGroupItem>
                </LinkContainer>
            ) : (
                <LinkContainer key="new" to="/timewindow/new">
                    <ListGroupItem>
                        <h4>
                            <b>{"\uFF0B"}</b> Crea una nuova sfida del giorno
                        </h4>
                    </ListGroupItem>
                </LinkContainer>
            )
        );
    }

    renderNotes() {
        return (
            <div className="notes">
                <PageHeader>Finestre temporali</PageHeader>
                <ListGroup>
                    {!this.state.isLoading && this.renderNotesList(this.state.notes)}
                </ListGroup>
            </div>
        );
    }

    render() {
        return <div className="Home">{this.renderNotes()}</div>;
    }
}

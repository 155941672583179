import React, { Component } from "react";
import { API } from "aws-amplify";
import {FormGroup, FormControl, ControlLabel, Button} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./NewNote.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import DateTimePicker from "react-datetime-picker";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import "./ADV.css";

class ADVForm extends React.Component {
  constructor() {
    super();
    this.state = {
      ADVs: []
    };
  }

  async componentDidMount() {
    try {
      const ADVs = await this.getADV();
      this.setState({ ADVs });
    } catch (e) {
      alert("aaa" + e);
    }
  }

  getADV() {
    return API.get("news", `/adv`);
  }
  handleDelete = async event => {
    const confirmed = window.confirm("Sicuro di cancellare?");

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await API.del("news", `/adv/${event.ADVID}`);;
      this.props.history.push("/adv");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  };

  saveADV(advs) {
    let body = { adv: advs };
    console.log(JSON.stringify(body));
    let response = API.post("news", `/adv`, {
      body: body
    });
    return response;
  }

  handleADVTypeChange = idx => evt => {
    const newADVs = this.state.ADVs.map((ADV, sidx) => {
      if (idx !== sidx) return ADV;
      return { ...ADV, type: evt.target.value };
    });

    this.setState({ ADVs: newADVs });
  };

  handleADVNameChange = idx => evt => {
    const newADVs = this.state.ADVs.map((ADV, sidx) => {
      if (idx !== sidx) return ADV;
      return { ...ADV, image_url: evt.target.value };
    });

    this.setState({ ADVs: newADVs });
  };

  handleADVUrlChange = idx => evt => {
    const newADVs = this.state.ADVs.map((ADV, sidx) => {
      if (idx !== sidx) return ADV;
      return { ...ADV, link: evt.target.value };
    });

    this.setState({ ADVs: newADVs });
  };

  handleSubmit = async evt => {
    evt.preventDefault();
    const { ADVs } = this.state;
    await this.saveADV(ADVs);
    this.props.history.push("/adv");
  };

  handleAddADV = () => {
    this.setState({
      ADVs: this.state.ADVs.concat([{ name: "", url: "" }])
    });
  };

  handleRemoveADV = idx => () => {
    this.handleDelete(this.state.ADVs[idx]);

    this.setState({
      ADVs: this.state.ADVs.filter((s, sidx) => idx !== sidx)
    });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h4>ADVs</h4>

        {this.state.ADVs.map((ADV, idx) => (
          <div className="ADV">
            <select
              type="text"
              placeholder={`ADV #${idx + 1} TYPE`}
              value={ADV.type}
              onChange={this.handleADVTypeChange(idx)}
            >
              <option value="_">Scegli..</option>
              <option value="live">LIVE TOP</option>
              <option value="schedina">SCHEDINA CENTER</option>
              <option value="rank">CELL IMAGE</option>
            </select>
            <FormGroup controlId="image_url">
              <FormControl
                  type="text"
                  placeholder={`ADV #${idx + 1} IMAGE URL`}
                  value={ADV.image_url}
                  onChange={this.handleADVNameChange(idx)}
                  componentClass="input"
              />
            </FormGroup>
            <FormGroup controlId="image_url">
              <FormControl
                  type="text"
                  placeholder={`URL to open #${idx + 1}`}
                  value={ADV.link}
                  onChange={this.handleADVUrlChange(idx)}
              />
            </FormGroup>

            <Button bsStyle="primary"  type="button"
                    onClick={this.handleRemoveADV(idx)}>Rimuovi</Button>

            <hr />
          </div>
        ))}

        <Button bsStyle="primary" type="button" onClick={this.handleAddADV}>
          Aggiungi
        </Button>

        <hr />
        <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            type="submit"
            text="Salva"
        />
      </form>
    );
  }
}

export default class ADVList extends Component {
  render() {
    const ADVFormWithRouter = withRouter(ADVForm);
    return <ADVFormWithRouter />;
  }
}

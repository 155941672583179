import React, { Component } from "react";
import {FormGroup, FormControl, ControlLabel, Button, ListGroupItem} from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import DateTimePicker from "react-datetime-picker";
import { CompactPicker } from "react-color";
import { colors } from "./teamcolors"

class MatchEditor extends Component {
  // async componentDidMount() {
  //   try {
  //     const schedina = this.values;
  //     const { teamHome, teamAway, matchID, quotaX } = schedina;
  //
  //
  //     this.setState({
  //       teamHome,
  //       teamAway,
  //       matchID,
  //       quotaX
  //     });
  //   } catch (e) {
  //     alert(e);
  //   }
  // }
  componentWillReceiveProps(props) {
    if (props.value) {
      const {
        matchDate,
        teamHome,
        teamAway,
        matchID,
        quotaX,
        quota1,
        quota2,
        teamHPColor,
        teamHSColor,
        teamAPColor,
        teamASColor,
        team_away,
        team_home,
        results
      } = props.value;

      this.setState({
        matchDate,
        teamHome,
        teamAway,
        matchID,
        quotaX,
        quota1,
        quota2,
        team_away,
        team_home,
        results
      });

      if (teamHPColor != null) {
        this.state.teamHPColor = teamHPColor;
      }
      if (teamHSColor != null) {
        this.state.teamHSColor = teamHSColor;
      }
      if (teamAPColor != null) {
        this.state.teamAPColor = teamAPColor;
      }
      if (teamASColor != null) {
        this.state.teamASColor = teamASColor;
      }


      // teamHPColor,
      // teamHSColor,
      // teamAPColor,
      // teamASColor,

    }
  }

  constructor(props) {
    super(props);
    this.matchID = parseInt(props["keyword"]);
    this.state = {
      matchDate: new Date(),
      teamHome: "",
      teamAway: "",
      matchID: "",
      quotaX: "0.0",
      quota1: "0.0",
      quota2: "0.0",
      teamHPColor: "",
      teamHSColor: "",
      teamAPColor: "",
      teamASColor: "",
      team_away: {
        name: "",
        primary_color: "",
        secondary_color: ""
      },
      team_home: {
        name: "",
        primary_color: "",
        secondary_color: ""
      },
      results: {
        1: "",
        2: "",
        X: ""
      }
    };

    this.teams = {
      Milan: {
        p: "#ff0000",
        s: "#000000"
      },
      Juventus: {
        p: "#ffffff",
        s: "#000000"
      },
      Inter: {
        p: "#000000",
        s: "#0080FF"
      },
      Sassuolo: {
        p: "#00FF00",
        s: "#000000"
      },
      Bologna: {
        p: "#ff0000",
        s: "#0000ff"
      },
      Atalanta: {
        p: "#000000",
        s: "#0080FF"
      },
      Brescia: {
        p: "#0080FF",
        s: "#ffffff"
      },
      Cagliari: {
        p: "#ff0000",
        s: "#0000FF"
      },
      Fiorentina: {
        p: "#5C2E91",
        s: "#5C2E91"
      },
      Genoa: {
        p: "#ff0000",
        s: "#0000ff"
      },
      Lazio: {
        p: "#B2FFFF",
        s: "#ffffff"
      },
      Lecce: {
        p: "#FFFF00",
        s: "#FF0000"
      },
      Napoli: {
        p: "#0080FF",
        s: "#0080FF"
      },
      Parma: {
        p: "#FFFF00",
        s: "#0000FF"
      },
      Roma: {
        p: "#FFFF00",
        s: "#FF0000"
      },
      Sampdoria: {
        p: "#FFFFFF",
        s: "#0000FF"
      },
      SPAL: {
        p: "#B2FFFF",
        s: "#ffffff"
      },
      Torino: {
        p: "#7A001F",
        s: "#7A001F"
      },
      Udinese: {
        p: "#ffffff",
        s: "#000000"
      },
      Verona: {
        p: "#FFFF00",
        s: "#0000FF"
      }
    };
  }

  componentWillUnmount() {
    // Make sure to remove the DOM listener when the component is unmounted.
    this.onSubmit(null);
  }

  onSubmit(e) {
    // alert("SUBMIT");
    // alert(this.state.quota1);
    this.state.team_away = {
      name: this.state.teamAway,
      primary_color: this.state.teamAPColor,
      secondary_color: this.state.teamASColor
    };
    this.state.team_home = {
      name: this.state.teamHome,
      primary_color: this.state.teamHPColor,
      secondary_color: this.state.teamHSColor
    };

    this.state.results = {
      1: this.state.quota1,
      2: this.state.quota2,
      X: this.state.quotaX
    };

    this.props.onChange(this.matchID, this.state);
  }

  handleFieldChange = event => {
    this.setState(
      {
        [event.target.id]: event.target.value
      },
      () => {}
    );

    if (event.target.id == "teamHome") {
      if (this.teams[event.target.value]) {
        this.state.teamHPColor = this.teams[event.target.value].p;
        this.state.teamHSColor = this.teams[event.target.value].s;
      }
    }

    if (event.target.id == "teamAway") {
      if (this.teams[event.target.value]) {
        this.state.teamAPColor = this.teams[event.target.value].p;
        this.state.teamASColor = this.teams[event.target.value].s;
      }
    }

    setTimeout(() => {
      this.onSubmit(null);
    }, 1000);
  };
  onChangeDate = matchDate => {
    this.setState({ matchDate });
    this.props.onChange(this.matchID, this.state);
  };

  handleChangeCompleteHP = color => {
    if (color == null) { return }
    this.setState({ teamHPColor: color.hex });
    this.props.onChange(this.matchID, this.state);
  };
  handleChangeCompleteHS = color => {
    if (color == null) { return }
    this.setState({ teamHSColor: color.hex });
    this.props.onChange(this.matchID, this.state);
  };
  handleChangeCompleteAP = color => {
    if (color == null) { return }
    this.setState({ teamAPColor: color.hex });
    this.props.onChange(this.matchID, this.state);
  };
  handleChangeCompleteAS = color => {
    
    this.setState({ teamASColor: color.hex });
    this.props.onChange(this.matchID, this.state);
  };

  render() {
    const loading = this.state.isProcessing || this.props.loading;

    return (
      <div>
        <FormGroup bsSize="small" controlId="matchDate">
          <ControlLabel>Inizio</ControlLabel>
          <DateTimePicker
            value={this.state.matchDate}
            onChange={this.onChangeDate}
            placeholder="matchDate"
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="matchID">
          <ControlLabel>Match ID</ControlLabel>
          <FormControl
            type="text"
            value={this.state.matchID}
            onChange={this.handleFieldChange}
            placeholder="matchID"
          />
          <Button bsStyle="primary" onClick={() => this.getMatchinfo()} >MatchInfo</Button>
          {/*<Button bsStyle="primary" onClick={() => this.getMatchOdds()} >Odds</Button>*/}
        </FormGroup>

        <FormGroup bsSize="small" controlId="teamHome">
          <ControlLabel>Team Home</ControlLabel>
          <FormControl
            type="text"
            value={this.state.teamHome}
            onChange={this.handleFieldChange}
            placeholder="Home"
          />
          <CompactPicker
            color={this.state.teamHPColor}
            onChangeComplete={this.handleChangeCompleteHP}
          />
          <CompactPicker
            color={this.state.teamHSColor}
            onChangeComplete={this.handleChangeCompleteHS}
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="teamAway">
          <ControlLabel>Team Away</ControlLabel>
          <FormControl
            type="text"
            value={this.state.teamAway}
            onChange={this.handleFieldChange}
            placeholder="Away"
          />
          <CompactPicker
            color={this.state.teamAPColor}
            onChangeComplete={this.handleChangeCompleteAP}
          />
          <CompactPicker
            color={this.state.teamASColor}
            onChangeComplete={this.handleChangeCompleteAS}
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="quota1">
          <ControlLabel>quota1</ControlLabel>
          <FormControl
            type="text"
            value={this.state.quota1}
            onChange={this.handleFieldChange}
            placeholder="quota1"
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="quotaX">
          <ControlLabel>quotaX</ControlLabel>
          <FormControl
            type="text"
            value={this.state.quotaX}
            onChange={this.handleFieldChange}
            placeholder="quotaX"
          />
        </FormGroup>

        <FormGroup bsSize="small" controlId="quota2">
          <ControlLabel>quota2</ControlLabel>
          <FormControl
            type="text"
            value={this.state.quota2}
            onChange={this.handleFieldChange}
            placeholder="quota2"
          />
        </FormGroup>

        <hr />
      </div>
    );
  }

  getMatchinfo() {

    let url = 'https://apiv3.apifootball.com/?action=get_events&match_id=' + this.state.matchID + '&APIkey=cb487510929e41e637ec2e72eb3205d3b4956303fdef528af0de6b747144c635';
    console.log(url);
    fetch(url)
        .then(res => res.json())
        .then((data) => {
          if (data.length <= 0) {
            return;
          }
          let match = data[0];
          console.log(data);
          console.log(match);
          console.log(match.match_hometeam_name);

          let teamHome = match.match_hometeam_name;
          let teamAway = match.match_awayteam_name;
          let matchDate = new Date(match.match_date + "T" + match.match_time);

          let teamHSColor = "#ffffff";
          let teamAPColor = "#ffffff";
          let teamHPColor = "#ffffff";
          let teamASColor = "#ffffff";

          if (colors[match.match_hometeam_id] && colors[match.match_awayteam_id]) {
            teamHSColor = colors[match.match_hometeam_id].s;
            teamAPColor = colors[match.match_awayteam_id].p;
            teamHPColor = colors[match.match_hometeam_id].p;
            teamASColor = colors[match.match_awayteam_id].s;
          }


          this.setState(
              {
                teamHome,
                teamAway,
                matchDate,
                teamHSColor,
                teamAPColor,
                teamHPColor,
                teamASColor

              }
          );

        })
  }

  getMatchOdds() {
    let url = 'https://3.apifootball.com/?action=get_odds&match_id=' + this.state.matchID + '&APIkey=cb487510929e41e637ec2e72eb3205d3b4956303fdef528af0de6b747144c635';
    console.log(url);
    fetch(url)
        .then(res => res.json())
        .then((data) => {
          if (data.length <= 0) {
            return;
          }
          let match = data[0];
          console.log(data);
          console.log(match);

          let quota1 = parseFloat(match.odd_1) * 10;
          let quotaX = parseFloat(match.odd_x) * 10;
          let quota2 = parseFloat(match.odd_2) * 10;


          this.setState(
              {
                quota1,
                quotaX,
                quota2

              }
          );

        })
  }
}

export default MatchEditor;

export const colors = {
  4186: {
    p: "#ff0000",
    s: "#000000"
  },
  4187: {
    p: "#ffffff",
    s: "#000000"
  },
  4189: {
    p: "#000000",
    s: "#0080FF"
  },
  4175: {
    p: "#00FF00",
    s: "#000000"
  },
  4185: {
    p: "#ff0000",
    s: "#0000ff"
  },
  4181: {
    p: "#000000",
    s: "#0080FF"
  },
  4203: {
    p: "#0080FF",
    s: "#ffffff"
  },
  4171: {
    p: "#ff0000",
    s: "#0000FF"
  },
  4173: {
    p: "#5C2E91",
    s: "#5C2E91"
  },
  4179: {
    p: "#ff0000",
    s: "#0000ff"
  },
  4183: {
    p: "#B2FFFF",
    s: "#ffffff"
  },
  4268: {
    p: "#FFFF00",
    s: "#FF0000"
  },
  4174: {
    p: "#0080FF",
    s: "#0080FF"
  },
  4192: {
    p: "#FFFF00",
    s: "#0000FF"
  },
  4184: {
    p: "#FFFF00",
    s: "#FF0000"
  },
  4176: {
    p: "#FFFFFF",
    s: "#0000FF"
  },
  4178: {
    p: "#B2FFFF",
    s: "#ffffff"
  },
  4170: {
    p: "#7A001F",
    s: "#7A001F"
  },
  4180: {
    p: "#ffffff",
    s: "#000000"
  },
  4188: {
    p: "#FFFF00",
    s: "#0000FF"
  },
  4195: {
    p: "#000000",
    s: "#FFFFFF"
  },
  4182: {
    p: "#ffff00",
    s: "#ff0000"
  },
  4177: {
    p: "#ffff00",
    s: "#0000ff"
  },
  4207: {
    p: "#7b1b02",
    s: "#7b1b02"
  },
  4269: {
    p: "#ff0000",
    s: "#0000ff"
  },
  4198: {
    p: "#d2d2d2",
    s: "#ff0000"
  },
  4172: {
    p: "#ff0000",
    s: "#0000ff"
  },
  4205: {
    p: "#007fff",
    s: "#007fff"
  },
  4209: {
    p: "#ffff00",
    s: "#007fff"
  },
  4257: {
    p: "#ffff00",
    s: "#0000ff"
  },
  4220: {
    p: "#e52b50",
    s: "#e52b50"
  },
  4199: {
    p: "#FF0000",
    s: "#ff0000"
  },
  4202: {
    p: "#FFFFFF",
    s: "#007fff"
  },
  4229: {
    p: "#000000",
    s: "#007fff"
  },
  4243: {
    p: "#000000",
    s: "#35682d"
  },
  4204: {
    p: "#7b1b02",
    s: "#7b1b02"
  },
  4206: {
    p: "#FFFFFF",
    s: "#000000"
  },
  4260: {
    p: "#7b1b02",
    s: "#7b1b02"
  },
  4208: {
    p: "#ffa500",
    s: "#35682d"
  },
  4201: {
    p: "#FFFFFF",
    s: "#99cbff"
  },
  4195 : {
    p: "#ffffff",
    s: "#000000"
  },
  4203 : {
    p: "#0070C0",
    s: "#ffffff"
  },
  4269 : {
    p: "#0070C0",
    s: "#ff0000"
  },
  4198: {
    p: "#808080",
    s: "#ff0000"
  },
  4209: {
    p: "#0070C0",
    s: "#FFFF00"
  },
  4268: {
    p: "#FFFF00",
    s: "#ff0000"
  },
  4245 : {
    p: "#ffffff",
    s: "#ff0000"
  },
  4225 : {
    p: "#ffffff",
    s: "#ff0000"
  },
  4229 : {
     p: "#ffffff",
    s: "#000000"
  },
  4243 : {
      p: "#00B050",
    s: "#000000"
  },
  4253 : {
     p: "#990033",
    s: "#990033"
  },
  7102 : {p:'#FFFFFF' ,s:'#FF0000'},
  7396 : {p:'#FFFF00' ,s:'#000000'},
  7105 : {p:'#0070C0' ,s:'#CC0099'},
  2616 : {p:'#0070C0' ,s:'#0070C0'},
  7767 : {p:'#5B9BD5' ,s:'#FFFFFF'},
  3022 : {p:'#FF0000' ,s:'#002060'},
  7271 : {p:'#FFFFFF' ,s:'#00B0F0'},
  7107 : {p:'#FFFF00' ,s:'#FFFF00'},
  930 : {p:'#FFFFFF' ,s:'#FF0000'},
  2627 : {p:'#FF0000' ,s:'#FF0000'},
  3225 : {p:'#FF0000' ,s:'#FF0000'},
  6418 : {p:'#5B9BD5' ,s:'#FFFFFF'},
  6119 : {p:'#FF0000' ,s:'#FF0000'},
  3236 : {p:'#FFFFFF' ,s:'#92D050'},
  
  
  
  
  
  7614 : {p:'#FFFFFF' ,s:'#000000'},
  4943 : {p:'#FFFF00' ,s:'#000000'},
  7106 : {p:'#FFFFFF' ,s:'#FF0000'},
  1107 : {p:'#000000' ,s:'#5B9BD5'},
  
  2621 : {p:'#FF0000' ,s:'#FF0000'},
  2626 : {p:'#FFFFFF' ,s:'#00B0F0'},
  6118 : {p:'#00B050' ,s:'#FFFFFF'},
  3230 : {p:'#FFFF00' ,s:'#000000'},
  7774 : {p:'#FFC000' ,s:'#000000'},
  6112 : {p:'#FFFFFF' ,s:'#0070C0'},
  3040 : {p:'#FF0000' ,s:'#0070C0'},
  7099 : {p:'#FFFFFF' ,s:'#FFFFFF'},
  
  
  5087 : {p:'#FFFFFF' ,s:'#FF0000'},

  2617 : {p:'#FFFFFF' ,s:'#FF0000'},
2632 : {p:'#7030A0' ,s:'#00B0F0'},
2613 : {p:'#0070C0' ,s:'#FFFFFF'},
2629 : {p:'#7030A0' ,s:'#00B0F0'},
2616 : {p:'#0070C0' ,s:'#0070C0'},
2619 : {p:'#0070C0' ,s:'#FF0000'},
2612 : {p:'#002060' ,s:'#002060'},

2653 : {p:'#FFFFFF' ,s:'#0070C0'},
2611 : {p:'#FFFFFF' ,s:'#FFFF00'},
2621 : {p:'#FF0000' ,s:'#FF0000'},
2626 : {p:'#FFFFFF' ,s:'#00B0F0'},
2627 : {p:'#FF0000' ,s:'#FF0000'},
2630 : {p:'#FFFFFF' ,s:'#000000'},

2614 : {p:'#FF0000' ,s:'#FFFFFF'},
2628 : {p:'#002060' ,s:'#FFFFFF'},

2620 : {p:'#0070C0' ,s:'#7030A0'},
2646 : {p:'#FFC000' ,s:'#000000'},
8330 : {p:'#FF0000' ,s:'#000000'},
8321 : {p:'#FF0000' ,s:'#FFFF00'},
8357 : {p:'#FF0000' ,s:'#002060'},
8341 : {p:'#FF0000' ,s:'#FFFFFF'},
8351 : {p:'#0070C0' ,s:'#FF0000'},
8315 : {p:'#CC0000' ,s:'#00B050'},
8310 : {p:'#FF0000' ,s:'#FF0000'},
8313 : {p:'#0070C0' ,s:'#FFFFFF'},
8323 : {p:'#FFFFFF' ,s:'#00B050'},
8345 : {p:'#FF0000' ,s:'#FFFFFF'},
8311 : {p:'#FFFFFF' ,s:'#0070C0'},
8348 : {p:'#FF0000' ,s:'#FFFFFF'},
8354 : {p:'#FF0000' ,s:'#FFFFFF'},
8359 : {p:'#002060' ,s:'#FFFFFF'},
8312 : {p:'#0070C0' ,s:'#000000'},
8319 : {p:'#FFFFFF' ,s:'#0070C0'},
8332 : {p:'#0070C0' ,s:'#FFFFFF'},
8314 : {p:'#0070C0' ,s:'#0070C0'},

8343 : {p:'#FFFFFF' ,s:'#FF0000'},
8350 : {p:'#000000' ,s:'#FFFFFF'},
8317 : {p:'#FF0000' ,s:'#FFFFFF'},
8316 : {p:'#00B0F0' ,s:'#FFFFFF'},
8318 : {p:'#FF0000' ,s:'#00B050'},
8336 : {p:'#FFFFFF' ,s:'#0070C0'},
8338 : {p:'#0070C0' ,s:'#FFFFFF'},
8331 : {p:'#0070C0' ,s:'#0070C0'},
8356 : {p:'#FFFF00' ,s:'#0070C0'},
8337 : {p:'#002060' ,s:'#FFFF00'},
8320 : {p:'#990033' ,s:'#FFFFFF'},
8335 : {p:'#0070C0' ,s:'#FF0000'},
8358 : {p:'#FFFF00' ,s:'#00B050'},
8322 : {p:'#FF0000' ,s:'#FFFFFF'},
8363 : {p:'#FF0000' ,s:'#FFFFFF'},
8340 : {p:'#0070C0' ,s:'#FFFF00'},
8361 : {p:'#FF0000' ,s:'#FFFF00'},
8324 : {p:'#FFC000' ,s:'#FFC000'},

8334 : {p:'#FF0000' ,s:'#FFFF00'},
8353 : {p:'#00B050' ,s:'#00B050'},
8352 : {p:'#002060' ,s:'#FF0000'},
8360 : {p:'#FF0000' ,s:'#FFFFFF'},
8326 : {p:'#FF0000' ,s:'#00B050'},
8347 : {p:'#00B050' ,s:'#FFFFFF'},
8355 : {p:'#FFFF00' ,s:'#FF0000'},
8698 : {p:'#FF0000' ,s:'#FFFFFF'},
8349 : {p:'#00B0F0' ,s:'#FFFFFF'},
8365 : {p:'#002060' ,s:'#FFFFFF'},
8342 : {p:'#FF0000' ,s:'#FFFFFF'},
8362 : {p:'#0070C0' ,s:'#FFFFFF'},
8364 : {p:'#0070C0' ,s:'#FFFFFF'},
8339 : {p:'#FF0000' ,s:'#FF0000'},
8325 : {p:'#0070C0' ,s:'#FFFF00'},
8327 : {p:'#FF0000' ,s:'#FFFFFF'},
8333 : {p:'#FF0000' ,s:'#FF0000'},
8344 : {p:'#0070C0' ,s:'#FFFF00'},

8346 : {p:'#FF0000' ,s:'#00B050'},

8303 : {p:'#00B0F0' ,s:'#FFFFFF'},
8309 : {p:'#00B050' ,s:'#FF0000'},
8300 : {p:'#FFFF00' ,s:'#00B050'},
8301 : {p:'#FF0000' ,s:'#002060'},
8307 : {p:'#FFFF00' ,s:'#0070C0'},
8302 : {p:'#FFFF00' ,s:'#0070C0'},
8304 : {p:'#FF0000' ,s:'#FFFFFF'},
8306 : {p:'#FF0000' ,s:'#FFFFFF'},
8308 : {p:'#00B0F0' ,s:'#00B0F0'},
8305 : {p:'#FFFF00' ,s:'#0070C0'},

4172 : {p:'#0070C0' ,s:'#FFFFFF'},

4207 : {p:'#990033' ,s:'#990033'},
4269 : {p:'#0070C0' ,s:'#FF0000'},
4198 : {p:'#808080' ,s:'#FF0000'},

4209 : {p:'#0070C0' ,s:'#FFFF00'},
4268 : {p:'#FFFF00' ,s:'#FF0000'},

4225 : {p:'#FFFFFF' ,s:'#FF0000'},

4229 : {p:'#000000' ,s:'#0070C0'},
4243 : {p:'#000000' ,s:'#00B050'},

4253 : {p:'#CC0000' ,s:'#CC0000'},

4178 : {p:'#FFFFFF' ,s:'#00B0F0'},
4268 : {p:'#FF0000' ,s:'#FFFF00'},
4211 : {p:'#FF0000' ,s:'#00B050'},
4219 : {p:'#808080' ,s:'#FF0000'},
4198 : {p:'#808080' ,s:'#FF0000'},
4270 : {p:'#4169E1' ,s:'#FFFFFF'},
4192 : {p:'#FFFF00' ,s:'#0070C0'}
};

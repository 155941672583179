import React, { Component } from "react";
import { API } from "aws-amplify";
import {FormGroup, FormControl, ControlLabel, Button} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./NewSchedina.css";
import DateTimePicker from "react-datetime-picker";
import MatchEditor from "../components/MatchEditor";
import DailyDareEditor from "../components/DailyDareEditor";

export default class TimeWindow extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: null,
            isDeleting: null,
            name: "",
            startDate: new Date(),
            endDate: new Date(),
            chart: [],
            id_finestra: null
        };
    }

    async componentDidMount() {
        try {
            const dailydare = await this.getTimeWindow();
            if (dailydare == null) {
                return;
            }
            var { name, id_finestra, chart } = dailydare;
            if (chart == undefined) {
                chart = [];
            }
            this.state.startDate = new Date(dailydare.start_date);
            this.state.endDate = new Date(dailydare.end_date);



            this.setState({
                name,
                id_finestra,
                chart
            });
        } catch (e) {
            alert(e);
        }
    }

    handleDelete = async event => {
        event.preventDefault();

        const confirmed = window.confirm("Sicuro di cancellare?");

        if (!confirmed) {
            return;
        }

        this.setState({ isDeleting: true });

        try {
            await this.deleteTimeWindow();
            this.props.history.push("/timewindow");
        } catch (e) {
            alert(e);
            this.setState({ isDeleting: false });
        }
    };

    getTimeWindow() {
        return API.get("news", `/timewindow_master/${this.props.match.params.id}`);
    }

    createTimeWindow(note) {
        console.log(note);
        if (this.state.id_finestra == null) {
            return API.put("news", "/timewindow_master", {
                body: note
            });
        } else {
            return API.post("news", "/timewindow_master", {
                body: note
            });
        }
    }
    deleteTimeWindow() {
        return API.del("news", `/timewindow_master/${this.props.match.params.id}`);
    }

    validateForm() {
        return true;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };
    onChangeStartDate = startDate => this.setState({ startDate });
    onChangeEndDate = endDate => this.setState({ endDate });

    onChange(field, value) {
        this.state.matches[field] = value;
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            await this.createTimeWindow(this.state);
            this.props.history.push("/timewindow");
        } catch (e) {
            alert(e + JSON.stringify(e));
            this.setState({ isLoading: false });
        }
    };

    handleCalculatorClick = async () => {

        this.setState({ isLoading: true });

        let chart =  await API.get("news", "/generatechart/" + this.state.id_finestra );

        this.setState({
            chart
        });

        this.setState({ isLoading: false });
    }

    render() {
        return (
            <div className="NewSchedina">
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="name">
                        <FormControl
                            onChange={this.handleChange}
                            value={this.state.name}
                            componentClass="input"
                        />
                    </FormGroup>
                    <FormGroup bsSize="small" controlId="startDate">
                        <ControlLabel>startDate</ControlLabel>
                        <DateTimePicker
                            value={this.state.startDate}
                            onChange={this.onChangeStartDate}
                            placeholder="startDate"
                        />
                    </FormGroup>
                    <FormGroup bsSize="small" controlId="endDate">
                        <ControlLabel>endDate</ControlLabel>
                        <DateTimePicker
                            value={this.state.endDate}
                            onChange={this.onChangeEndDate}
                            placeholder="endDate"
                        />
                    </FormGroup>
                    <hr /> <hr />
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Create"
                        loadingText="Creating…"
                    />
                    <LoaderButton
                        block
                        bsStyle="danger"
                        bsSize="large"
                        isLoading={this.state.isDeleting}
                        onClick={this.handleDelete}
                        text="Delete"
                        loadingText="Deleting…"
                    />
                </form>

                <Button bsStyle="primary" onClick={() => this.handleCalculatorClick()} >Calcola</Button>

                <hr />
                {this.state.chart.map((item, idx) => (
                    <p>{item.position} - {item.name} - {item.points}</p>
                ))}
            </div>
        );
    }
}

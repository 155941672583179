import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { PageHeader, Table, ListGroupItem, Button } from "react-bootstrap";
import "./Notes.css";

export default class UsersList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            notes: []
        };

    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const response = await this.notes();
            let notes = response.user;
            notes = notes.sort(function(a, b) {
            a = new Date(a.UserCreateDate);
            b = new Date(b.UserCreateDate);
            return a < b ? 1 : a > b ? -1 : 0;
            });

            this.setState({ notes });
        } catch (e) {
            alert("aaa" + e);
        }

        this.setState({ isLoading: false });
    }

    notes() {
        return API.get("news", "/user_list");
    }

    getValueForKey(object, key) {
        let obj = object.Attributes.filter(function(att) {
            return att.Name  === key;
        });
        if (obj.length === 0) {
            return "";
        }
        return obj[0].Value;
    }

    renderNotesList(notes) {
        return notes.map((note, i) =>

            <tr>
                <td>{note.UserStatus == "CONFIRMED" ? "🟢" : "📨" } {note.Username} </td>
                <td>{this.getValueForKey(note, "name")}</td>
                <td>{this.getValueForKey(note, "family_name")}</td>
                <td>{new Date(note.UserCreateDate).toLocaleString()}</td>
                <td><Button bsSize="xsmall"  onClick={() => this.openUserDetail(note)}>Dettaglio</Button></td>
            </tr>

        );
    }

    renderNotes() {
        return (
            <div className="notes">
                <PageHeader>Utenti</PageHeader>

                <Table striped bordered condensed hover>
                    <thead>
                    <tr>
                        <th>#username</th>
                        <th>Nome</th>
                        <th>Cognome</th>
                        <th>Data registrazione</th>
                        <th> </th>
                    </tr>
                    </thead>
                    <tbody>
                    {!this.state.isLoading && this.renderNotesList(this.state.notes)}
                    </tbody>
                </Table>
            </div>
        );
    }

    render() {
        return <div className="Home">{this.renderNotes()}</div>;
    }

    openUserDetail(user) {
            let userSub = this.getValueForKey(user, "sub");
        this.props.history.push('user/' + userSub);
    }
}

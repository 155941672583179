import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import "./Home.css";
import { Dashboard } from "../views/index.js"

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }


    this.setState({ isLoading: false });
  }


  renderDashboard() {
    return (


                <Dashboard />


    )
    ;
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>BarSport</h1>
        <p>DashBoard</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  renderHome() {
    return (
      <div className="notes">
        <PageHeader>HOME</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderDashboard()}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderHome() : this.renderLander()}
      </div>
    );
  }
}

import React, { Component } from "react";
import { API } from "aws-amplify";
import {Table, FormControl, ControlLabel, Button} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./NewSchedina.css";
import DailyDareEditor from "../components/DailyDareEditor";
import DateTimePicker from "react-datetime-picker";
import MatchEditor from "../components/MatchEditor";

export default class UserDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user : { Attributes : [] },
            schedine : [],
            dailydare : []
        };
    }

    async componentDidMount() {
        try {
            let attachmentURL;
            const userInfos = await this.getUserInfo();
            if (userInfos == null) {
                return;
            }
            const { user, schedine, dailydare } = userInfos;
            this.setState({
                user,
                schedine,
                dailydare
            });
        } catch (e) {
            alert(e);
        }
    }


    getUserInfo() {
        return API.get("news", `/user_detail/${this.props.match.params.id}`);
    }


    render() {
        return (
            <div className="User">

                <h1>{this.state.user.Username}</h1>

                <Table striped bordered condensed hover>
                    <tbody>
                    { (this.state.user.Attributes.map((attribute, i) =>

                    <tr>
                        <td>{attribute.Name}</td>
                        <td>{attribute.Value}</td>
                    </tr>

                    ))}

                    </tbody>
                </Table>

                <hr />

                <h2>Schedine</h2>

                <Table striped bordered condensed hover>
                    <tbody>
                    { (this.state.schedine.map((schedina, i) =>

                        <tr>
                            <td>{schedina.name}</td>
                            <td>{schedina.insertDate}</td>
                            <td>{schedina.points}</td>
                            <td>{this.formattedMatch(schedina)}</td>

                        </tr>

                    ))}
                    </tbody>
                </Table>

                <h2>Sfide del giorno</h2>

                <Table striped bordered condensed hover>
                    <tbody>
                    { (this.state.dailydare.map((dd, i) =>

                        <tr>
                            <td>{dd.insertDate}</td>
                            <td>{this.formatDDMatch(dd)}</td>
                        </tr>

                    ))}
                    </tbody>
                </Table>

            </div>
        );
    }

    formattedMatch(schedina) {
        if (schedina.matches === undefined) {
            return "-";
        }
        return  schedina.matches.map(function(match) {
                let played = match.result_played;

                if (played.length > 1) {
                    return "[" + played + "]";
                }
                return played;
            })

    }

    formatDDMatch(dd) {
        if (dd.matches === undefined) {
            return "-";
        }
        return  dd.matches.map(function(match) {

            return match.team_home.name + " - " + match.team_away.name + " >> " + match.result_played;


        }).join(" || ");


    }
}

import React, { Component } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewNote.css";

export default class apifootball extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      league_id: "",
      start: "",
      end: "",
      matchesAPI: [],
    };
  }

  requestAPIFootBall(apn) {
    console.log(apn);

    fetch('https://apiv3.apifootball.com/?action=get_events&from=' + this.state.start + '&to=' + this.state.end + '&league_id=' + this.state.league_id + '&APIkey=cb487510929e41e637ec2e72eb3205d3b4956303fdef528af0de6b747144c635')
    .then(res => res.json())
    .then((data) => {
      this.setState ({ matchesAPI : data})

    })
  }
  createAPIMatches = () => {
    let table = [];

    this.state.matchesAPI.forEach(function(element) {
      table.push(
        <li>
          {element.match_id} - {element.match_hometeam_name} - {element.match_awayteam_name} ({element.match_date})
        </li>
      );
    });
    return table;
  };
  validateForm() {
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };


  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.requestAPIFootBall({
        league_id: this.state.league_id,
        start: this.state.start,
        end: this.state.end
      });

    } catch (e) {
      alert(e);

    }

    this.setState({ isLoading: false });
  };

  render() {
    const { editorState } = this.state;
    return (
      <div className="apifootball">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="league_id">
          <ControlLabel>league_id</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.league_id}
              componentClass="input"
              placeholder="serie a 207, serie b 206"
            />
            <a href="https://apifootball.com/coverage/#World" target="_blank">Lista league_id</a>
          </FormGroup>
          <FormGroup controlId="start">
          <ControlLabel>start</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.start}
              componentClass="input"
              placeholder="YYYY-MM-DD"
            />
          </FormGroup>
          <FormGroup controlId="end">
          <ControlLabel>end</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.end}
              componentClass="input"
              placeholder="YYYY-MM-DD"
            />
          </FormGroup>

          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>

        {this.createAPIMatches()}

      </div>
    );
  }
}

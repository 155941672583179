const dev = {
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://7fpim902jc.execute-api.eu-west-1.amazonaws.com/dash"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_obogYZs3X",
    APP_CLIENT_ID: "3gql1up7e4qo92l876p1o58c9i",
    IDENTITY_POOL_ID: "eu-west-1:dbb075ec-6203-4617-8b0a-6c3256c86658"
  },
  s3: {
    REGION: "eu-west-1",
    BUCKET: "barsportapp/news"
  },
  Analytics: {
    disabled: true
  }
};

const prod = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  s3: {
    REGION: "eu-west-1",
    BUCKET: "barsportapp/news"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://zhcs68j864.execute-api.eu-west-1.amazonaws.com/dash"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_obogYZs3X",
    APP_CLIENT_ID: "3gql1up7e4qo92l876p1o58c9i",
    IDENTITY_POOL_ID: "eu-west-1:dbb075ec-6203-4617-8b0a-6c3256c86658"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};

import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import {FormGroup, FormControl, ControlLabel, Table} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./Notes.css";

import MatchEditor from "../components/MatchEditor";
import DateTimePicker from "react-datetime-picker";

export default class DailyDareStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stats: [],
            schedine: [],
        };
    }

    async componentDidMount() {
        try {
            const api = await this.getStats();
            const { schedine, stats } = api;


            this.setState({
                schedine,
                stats
            });
        } catch (e) {
            alert(e);
        }
    }

    getStats() {
        return API.get("news", `/stats/dailydare_user/${this.props.match.params.id}`);
    }

    formattedMatch(schedina) {
        if (schedina.matches === undefined) {
            return "-";
        }
        return  schedina.matches.map(function(match) {
            let played = match.result_played;

            if (played.length > 1) {
                return "[" + played + "]";
            }
            return played;
        })

    }

    render() {
        function openUserDetail(userSub) {
            this.props.history.push('user/' + userSub);
        }

        return (
            <div className="DailyDareStat">
                <h2>Giocate</h2>

                <Table striped bordered condensed hover>
                    <tbody>
                    { (this.state.schedine.map((schedina, i) =>

                        <tr>
                            <td onClick={() => openUserDetail(schedina.user_id)}>{schedina.user_id}</td>
                            <td>{schedina.insertDate}</td>
                            <td>{this.formattedMatch(schedina)}</td>

                        </tr>

                    ))}
                    </tbody>
                </Table>


                <h2>Statistiche</h2>

                <Table striped bordered condensed hover>
                    <thead>

                        <th>Match</th>
                    <th>1</th>
                    <th>X</th>
                    <th>2</th>
                    <th>N. Giocate</th>
                    <th>Answer</th>

                    </thead>
                    <tbody>
                    { (this.state.stats.map((match, i) =>

                        <tr>
                            <td>{match.team_home.name} - {match.team_away.name} - {match.question}</td>
                            <td>{match.stats["1"]} ({(match.stats["1"]/match.stats["total"]*100).toFixed(2)}%)</td>
                            <td>{match.stats["X"]} ({(match.stats["X"]/match.stats["total"]*100).toFixed(2)}%)</td>
                            <td>{match.stats["2"]} ({(match.stats["2"]/match.stats["total"]*100).toFixed(2)}%)</td>
                            <td>{match.stats["total"]}</td>
                            <td>{match.answer}</td>

                        </tr>

                    ))}
                    </tbody>
                </Table>

            </div>
        );
    }
}

import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, Image } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./Notes.css";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import DateTimePicker from "react-datetime-picker";

export default class Notes extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      isDeleting: null,
      content: "",
      title: "",
      subtitle: "",
      image: "",
      editorState: EditorState.createEmpty()
    };
  }

  async componentDidMount() {
    try {
      const note = await this.getNote();
      let {
        content,
        title,
        subtitle,
        detail_url,
        pubDate,
        video,
        image
      } = note;
      let fetcha = await fetch(detail_url);
      // alert(fetcha);
      let res = await fetcha.text();
      this.setState({ content: res });


      pubDate = new Date(pubDate);

      const contentBlock = htmlToDraft(res);
      // alert(contentBlock);

      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      // })
      // .catch(console.log)

      const rawContentState = convertToRaw(editorState.getCurrentContent());

    const markup = draftToHtml(rawContentState, {
      trigger: "#",
      separator: " "
    });



      this.setState({
        content,
        title,
        subtitle,
        editorState,
        pubDate,
        video,
        image
      });

      this.setState({
        content: markup
      });
    } catch (e) {
      alert(e);
    }
  }

  onEditorStateChange: Function = editorState => {
    this.setState({
      editorState
    });

    const rawContentState = convertToRaw(editorState.getCurrentContent());

    const markup = draftToHtml(rawContentState, {
      trigger: "#",
      separator: " "
    });

    this.setState({
      content: markup
    });
  };

  getNote() {
    return API.get("news", `/masternews/${this.props.match.params.id}`);
  }

  saveNote(note) {
    return API.put("news", `/masternews/${this.props.match.params.id}`, {
      body: note
    });
  }

  deleteNote() {
    return API.del("news", `/masternews/${this.props.match.params.id}`);
  }

  validateForm() {
    return true;
  }

  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleFileChange = event => {
    this.file = event.target.files[0];
  };
  handleFileVideoChange = event => {
    this.video = event.target.files[0];
  };
  onChangePubDate = pubDate => this.setState({ pubDate });

  handleSubmit = async event => {
    let attachment;
    let video;

    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    this.setState({ isLoading: true });

    try {
      if (this.file) {
        attachment = await s3Upload(this.file);
        this.setState({
          image:
            "https://barsportapp.s3-eu-west-1.amazonaws.com/news/public/" +
            attachment
        });
      } else {
      }
      if (this.video) {
        video = await s3Upload(this.video);
        this.setState({
          video:
            "https://barsportapp.s3-eu-west-1.amazonaws.com/news/public/" +
            video
        });
      }

      await this.saveNote({
        content: this.state.content,
        title: this.state.title,
        subtitle: this.state.subtitle,
        pubDate: this.state.pubDate,
        attachment: this.state.image,
        video: this.state.video
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteNote();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  };

  render() {
    const { editorState } = this.state;
    return (
      <div className="NewNote">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="title">
            <FormControl
              onChange={this.handleChange}
              value={this.state.title}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="subtitle">
            <FormControl
              onChange={this.handleChange}
              value={this.state.subtitle}
              componentClass="input"
            />
          </FormGroup>

          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={this.onEditorStateChange}
          />

          <FormGroup bsSize="small" controlId="startDate">
            <ControlLabel>Pub Date</ControlLabel>
            <DateTimePicker
              value={this.state.pubDate}
              onChange={this.onChangePubDate}
              placeholder="Publication Date"
            />
          </FormGroup>
          <Image
            style={{ width: 300, height: "auto" }}
            src={this.state.image}
          />
          <FormGroup controlId="file">
            <ControlLabel>Attachment</ControlLabel>
            <FormControl onChange={this.handleFileChange} type="file" />
          </FormGroup>
          <FormGroup controlId="file">
            <ControlLabel>VIDEO</ControlLabel>
            <FormControl onChange={this.handleFileVideoChange} type="file" />
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
          <LoaderButton
            block
            bsStyle="danger"
            bsSize="large"
            isLoading={this.state.isDeleting}
            onClick={this.handleDelete}
            text="Delete"
            loadingText="Deleting…"
          />
        </form>
      </div>
    );
  }
}

import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, Button } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./Notes.css";

import MatchEditor from "../components/MatchEditor";
import DateTimePicker from "react-datetime-picker";

export default class Schedina extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      isDeleting: null,
      name: "",
      startDate: new Date(),
      endDate: new Date(),
      matches: []
      // ,
      // match_count: 13
    };
  }

  async componentDidMount() {
    try {
      let attachmentURL;
      const schedina = await this.getNote();
      const { name, matches } = schedina;
      this.state.startDate = new Date(schedina.start_date);
      this.state.endDate = new Date(schedina.end_date);
      this.state.startLive = new Date(schedina.start_live);
      this.state.endLive = new Date(schedina.end_live);
      // if (schedina.match_count != undefined) {
      //   this.state.match_count = schedina.match_count;
      // }
      this.setState({
        name,
        matches
      });
    } catch (e) {
      alert(e);
    }
  }

  getNote() {
    return API.get("news", `/readschedinamaster/${this.props.match.params.id}`);
  }

  saveSchedina(note) {
    return API.put(
      "news",
      `/editschedinamaster/${this.props.match.params.id}`,
      {
        body: note
      }
    );
  }

  deleteNote() {
    return API.del(
      "news",
      `/deleteschedinamaster/${this.props.match.params.id}`
    );
  }
  openStats = () => {
    this.props.history.push('/schedinastats/' + this.props.match.params.id);
  }
  validateForm() {
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };
  onChangeStartDate = startDate => this.setState({ startDate });
  onChangeEndDate = endDate => this.setState({ endDate });
  onChangeStartLive = startLive => this.setState({ startLive });
  onChangeEndLive = endLive => this.setState({ endLive });

  onChange(field, value) {
    this.state.matches[field] = value;
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.saveSchedina(this.state);
      this.props.history.push("/");
    } catch (e) {
      alert(e + JSON.stringify(e));
      this.setState({ isLoading: false });
    }
  };

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteNote();
      this.props.history.push("/schedine");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  };

  createTable = () => {
    let table = [];
    for (let i = 0; i < 13; i++) {
      table.push(
        <MatchEditor
          key={i}
          value={this.state.matches[i]}
          currentMatch={JSON.stringify(this.state.matches[i])}
          keyword={i}
          onChange={this.onChange.bind(this)}
        />
      );
    }
    return table;
  };

  render() {
    return (
      <div className="EditSchedina">
        <Button bsStyle="warning" onClick={() => this.openStats()} >STATS</Button>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="name">
            <FormControl
              onChange={this.handleChange}
              value={this.state.name}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup bsSize="small" controlId="startDate">
            <ControlLabel>startDate</ControlLabel>
            <DateTimePicker
              value={this.state.startDate}
              onChange={this.onChangeStartDate}
              placeholder="startDate"
            />
          </FormGroup>
          <FormGroup bsSize="small" controlId="endDate">
            <ControlLabel>endDate</ControlLabel>
            <DateTimePicker
              value={this.state.endDate}
              onChange={this.onChangeEndDate}
              placeholder="endDate"
            />
          </FormGroup>
          <hr /> <hr />
          <FormGroup bsSize="small" controlId="startLive">
            <ControlLabel>startLive</ControlLabel>
            <DateTimePicker
              value={this.state.startLive}
              onChange={this.onChangeStartLive}
              placeholder="startLive"
            />
          </FormGroup>
          <FormGroup bsSize="small" controlId="endLive">
            <ControlLabel>endLive</ControlLabel>
            <DateTimePicker
              value={this.state.endLive}
              onChange={this.onChangeEndLive}
              placeholder="endLive"
            />
          </FormGroup>
          {/* <FormGroup controlId="match_count">
          <ControlLabel>Numero di partite</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.match_count}
              componentClass="input"
            />
          </FormGroup> */}
          <hr /> <hr />
          {this.createTable()}
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
          <LoaderButton
            block
            bsStyle="danger"
            bsSize="large"
            isLoading={this.state.isDeleting}
            onClick={this.handleDelete}
            text="Delete"
            loadingText="Deleting…"
          />
        </form>
      </div>
    );
  }
}

import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { PageHeader, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import "./Notes.css";

export default class Schedine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const notes = await this.notes();
      this.setState({ notes });
    } catch (e) {
      alert("aaa" + e);
    }

    this.setState({ isLoading: false });
  }

  notes() {
    return API.get("news", "/listschedinamaster");
  }

  handleCalculatorClick = (schedina_id) => {
    alert(schedina_id);
    console.log(schedina_id);
    this.setState({ isLoading: true });

    API.get("news", "/generateresults/" + schedina_id );

    this.setState({ isLoading: false });
  }

  openStats = (schedina_id) => {
    this.props.history.push('/schedinastats/' + schedina_id);
  }

  renderNotesList(notes) {
    return [{}].concat(notes).map((note, i) =>
      i !== 0 ? (
        <LinkContainer key={note._id} to={`/schedina/${note._id}`}>
          <ListGroupItem
            header={note.name + " - (Match: " + note.matches.length + ")"}
          >
            // {"Start: " + note.start_date} {"end: " + note.end_date}
            <br /><Button bsStyle="primary" onClick={() => this.handleCalculatorClick(note._id)} >Calcola</Button>
            <br /><Button bsStyle="warning" onClick={() => this.openStats(note._id)} >STATS</Button>
          </ListGroupItem>

        </LinkContainer>
      ) : (
        <LinkContainer key="new" to="/schedina/new">
          <ListGroupItem>
            <h4>
              <b>{"\uFF0B"}</b> Create a new schedina
            </h4>
          </ListGroupItem>
        </LinkContainer>
      )
    );
  }

  renderNotes() {
    return (
      <div className="notes">
        <PageHeader>Schedine</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderNotesList(this.state.notes)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return <div className="Home">{this.renderNotes()}</div>;
  }
}

import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { PageHeader, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import "./Notes.css";

export default class DailyDareList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: []
    };

   }

   handleCalculatorClick = (dailydare_id) => {
     alert(dailydare_id);
     console.log(dailydare_id);
     this.setState({ isLoading: true });

     API.get("news", "/dailydare_master_calculator/" + dailydare_id );

     this.setState({ isLoading: false });
   }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const notes = await this.notes();
      this.setState({ notes });
    } catch (e) {
      alert("aaa" + e);
    }

    this.setState({ isLoading: false });
  }

  notes() {
    return API.get("news", "/dailydare_master");
  }

  renderNotesList(notes) {
    return [{}].concat(notes).map((note, i) =>
      i !== 0 ? (
        <LinkContainer key={note.dailydare_id} to={`/dailydare/${note.dailydare_id}`}>
          <ListGroupItem
            header={note.name + " - (Match: " + note.matches.length + ")"}
          >
          <Button bsStyle="primary" onClick={() => this.handleCalculatorClick(note.dailydare_id)} >Calcola</Button>
          </ListGroupItem>
        </LinkContainer>
      ) : (
        <LinkContainer key="new" to="/dailydare/new">
          <ListGroupItem>
            <h4>
              <b>{"\uFF0B"}</b> Crea una nuova sfida del giorno
            </h4>
          </ListGroupItem>
        </LinkContainer>
      )
    );
  }

  renderNotes() {
    return (
      <div className="notes">
        <PageHeader>Sfide del giorno</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderNotesList(this.state.notes)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return <div className="Home">{this.renderNotes()}</div>;
  }
}

import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Notes from "./containers/Notes";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NewsList  from "./containers/NewsList"
import NewNote from "./containers/NewNote";
import NewSchedina from "./containers/NewSchedina";
import DailyDare from "./containers/DailyDare";
import DailyDareList from "./containers/DailyDareList";
import DailyDareStats from "./containers/DailyDareStats"
import TimeWindowList from "./containers/TimeWindowsList";
import TimeWindow from "./containers/TimeWindow";
import Schedina from "./containers/Schedina";
import Schedine from "./containers/Schedine";
import SchedinaStats from "./containers/SchedinaStats"
import Settings from "./containers/Settings";
import ADVList from "./containers/ADV";
import NewPush from "./containers/NewPush";
import apifootball from "./containers/apifootball";
import UsersList from "./containers/UsersList";
import UserDetail from "./containers/UserDetail";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute
      path="/login"
      exact
      component={Login}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/signup"
      exact
      component={Signup}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/settings"
      exact
      component={Settings}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/notes/new"
      exact
      component={NewNote}
      props={childProps}
    />
      <AuthenticatedRoute
          path="/news"
          exact
          component={NewsList}
          props={childProps}
      />

    <AuthenticatedRoute
      path="/schedina/new"
      exact
      component={NewSchedina}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/notes/:id"
      exact
      component={Notes}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/schedina/:id"
      exact
      component={Schedina}
      props={childProps}
    />
      <AuthenticatedRoute
          path="/schedinastats/:id"
          exact
          component={SchedinaStats}
          props={childProps}
      />
    <AuthenticatedRoute
      path="/schedine"
      exact
      component={Schedine}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/dailydare/:id"
      exact
      component={DailyDare}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/dailydare"
      exact
      component={DailyDareList}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/dailydare/new"
      exact
      component={DailyDare}
      props={childProps}
    />
      <AuthenticatedRoute
          path="/dailydare/stats/:id"
          exact
          component={DailyDareStats}
          props={childProps}
      />
      <AuthenticatedRoute
          path="/timewindow"
          exact
          component={TimeWindowList}
          props={childProps}
      />
      <AuthenticatedRoute
          path="/timewindow/:id"
          exact
          component={TimeWindow}
          props={childProps}
      />
      <AuthenticatedRoute
          path="/timewindow/new"
          exact
          component={TimeWindow}
          props={childProps}
      />
    <AuthenticatedRoute
      path="/adv"
      exact
      component={ADVList}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/apifootball"
      exact
      component={apifootball}
      props={childProps}
    />
      <AuthenticatedRoute
          path="/users"
          exact
          component={UsersList}
          props={childProps}
      />
      <AuthenticatedRoute
          path="/user/:id"
          exact
          component={UserDetail}
          props={childProps}
      />

    <AuthenticatedRoute
      path="/newpush"
      exact
      component={NewPush}
      props={childProps}
    />

    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);

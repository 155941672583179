import React, { Component } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./NewSchedina.css";
import MatchEditor from "../components/MatchEditor";
import DateTimePicker from "react-datetime-picker";

export default class NewSchedina extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      name: "",
      startDate: new Date(),
      endDate: new Date(),
      startLive: new Date(),
      endLive: new Date(),
      matches: [],
      matchesAPI: []
      // match_count: 13
    };
  }

  componentDidMount() {
    // fetch('https://apiv2.apifootball.com/?action=get_events&from=2019-09-19&to=2019-09-22&league_id=262&APIkey=cb487510929e41e637ec2e72eb3205d3b4956303fdef528af0de6b747144c635')
    // .then(res => res.json())
    // .then((data) => {
    //   this.setState ({ matchesAPI : data})
    //
    // })
    // .catch(console.log)
  }

  creatSchedina(note) {
    return API.post("news", "/addschedinamaster", {
      body: note
    });
  }

  validateForm() {
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };
  onChangeStartDate = startDate => this.setState({ startDate });
  onChangeEndDate = endDate => this.setState({ endDate });
  onChangeStartLive = startLive => this.setState({ startLive });
  onChangeEndLive = endLive => this.setState({ endLive });

  onChange(field, value) {
    this.state.matches[field] = value;
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.creatSchedina(this.state);
      this.props.history.push("/");
    } catch (e) {
      alert(e + JSON.stringify(e));
      this.setState({ isLoading: false });
    }
  };

  createTable = () => {
    let table = [];

    for (let i = 0; i < 13; i++) {
      table.push(
        <MatchEditor
          key={i}
          value={this.state.matches[i]}
          keyword={i}
          onChange={this.onChange.bind(this)}
        />
      );
    }
    return table;
  };

  createAPIMatches = () => {
    let table = [];

    this.state.matchesAPI.forEach(function(element) {
      table.push(
        <li>
          {element.match_hometeam_name} -{element.match_awayteam_name} -
          {element.match_id}
        </li>
      );
    });
    return table;
  };

  render() {
    return (
      <div className="NewSchedina">
        {this.createAPIMatches()}

        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="name">
            <FormControl
              onChange={this.handleChange}
              value={this.state.name}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup bsSize="small" controlId="startDate">
            <ControlLabel>startDate</ControlLabel>
            <DateTimePicker
              value={this.state.startDate}
              onChange={this.onChangeStartDate}
              placeholder="startDate"
            />
          </FormGroup>
          <FormGroup bsSize="small" controlId="endDate">
            <ControlLabel>endDate</ControlLabel>
            <DateTimePicker
              value={this.state.endDate}
              onChange={this.onChangeEndDate}
              placeholder="endDate"
            />
          </FormGroup>
          <hr /> <hr />
          <FormGroup bsSize="small" controlId="startLive">
            <ControlLabel>startLive</ControlLabel>
            <DateTimePicker
              value={this.state.startLive}
              onChange={this.onChangeStartLive}
              placeholder="startLive"
            />
          </FormGroup>
          <FormGroup bsSize="small" controlId="endLive">
            <ControlLabel>endLive</ControlLabel>
            <DateTimePicker
              value={this.state.endLive}
              onChange={this.onChangeEndLive}
              placeholder="endLive"
            />
          </FormGroup>
          {/* <FormGroup controlId="match_count">
          <ControlLabel>Numero di partite</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.match_count}
              componentClass="input"
            />
          </FormGroup> */}
          <hr /> <hr />
          {this.createTable()}
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}
 